import axios from 'axios';
import React, { Component, Fragment, Suspense, lazy } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

import AppURL from '../api/AppURL'


const ProductPage = React.lazy(() => import("../pages/ProductPage"))
const Terms = React.lazy(() => import("../pages/Terms"))
const Privacy = React.lazy(() => import("../pages/Privacy"))
const Home = React.lazy(() => import("../pages/Home"))
const Gallery = React.lazy(() => import("../pages/Gallery"))
const Video = React.lazy(() => import("../pages/Video"))
const Clients = React.lazy(() => import("../pages/Clients"))
const Testimonials = React.lazy(() => import("../pages/Testimonials"))
const Blog = React.lazy(() => import("../pages/Blog"))
const LatestNews = React.lazy(() => import("../pages/LatestNews"))
const Downloads = React.lazy(() => import("../pages/Downloads"))
const Contact = React.lazy(() => import("../pages/Contact"))
const Faqs = React.lazy(() => import("../pages/Faqs"))
const BlogDetails = React.lazy(() => import("../pages/BlogDetails"))
const LatestNewsDetails = React.lazy(() => import("../pages/LatestNewsDetails"))
const Page = React.lazy(() => import("../pages/Page"))
const ProductMenu = React.lazy(() => import("../components/category/ProductMenu"))
const ProductCategory = React.lazy(() => import("../components/category/ProductCategory"))

const FavouritePage = React.lazy(() => import("../pages/FavouritePage"))
const CartPage = React.lazy(() => import("../pages/CartPage"))

const UserLogin = React.lazy(() => import("../components/user/UserLogin"))
const RegisterPage = React.lazy(() => import("../components/user/RegisterPage"))
const ForgetPasswordPage = React.lazy(() => import("../components/user/ForgetPasswordPage"))
const ResetPassword = React.lazy(() => import("../components/user/ResetPassword"))
const ProfilePage = React.lazy(() => import("../components/user/ProfilePage"))

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {

  constructor() {
    super();
    this.state = {
      user: {}
    }

  }

  componentDidMount() {

    axios.get(AppURL.UserData).then((response) => {
      this.setUser(response.data)

    }).catch(error => {

    });

  }

  setUser = (user) => {

    this.setState({ user: user })

  }

  render() {
    return (

      <Fragment>
        <Suspense fallback={<h1>Loding...</h1>}>
          <Header user={this.state.user} setUser={this.setUser} />

          <Router basename={process.env.PUBLIC_URL} history={history}>

            <Route exact path='/' render={(props) => <Home {...props} key={Date.now()} />} />
            <Route exact path='/gallery/:page_url' render={(props) => <Gallery {...props} key={Date.now()} />} />
            <Route exact path='/video/:page_url' render={(props) => <Video {...props} key={Date.now()} />} />
            <Route exact path='/clients/:page_url' render={(props) => <Clients {...props} key={Date.now()} />} />
            <Route exact path='/blog/:page_url' render={(props) => <Blog {...props} key={Date.now()} />} />
            <Route exact path='/testimonials/:page_url' render={(props) => <Testimonials {...props} key={Date.now()} />} />
            <Route exact path='/news/:page_url' render={(props) => <LatestNews {...props} key={Date.now()} />} />
            <Route exact path='/downloads/:page_url' render={(props) => <Downloads {...props} key={Date.now()} />} />
            <Route exact path='/contact-us/:page_url' render={(props) => <Contact {...props} key={Date.now()} />} />
            <Route exact path='/faqs/:page_url' render={(props) => <Faqs {...props} key={Date.now()} />} />
            <Route exact path='/blogdetails/:blog_url' render={(props) => <BlogDetails {...props} key={Date.now()} />} />
            <Route exact path='/news/:page_url' render={(props) => <LatestNewsDetails {...props} key={Date.now()} />} />
            <Route exact path='/:page_url' render={(props) => <Page {...props} user={this.state.user} key={Date.now()} />} />
            <Route exact path='/privacy/privacy' render={(props) => <Privacy {...props} key={Date.now()} />} />
            <Route exact path='/terms/terms' render={(props) => <Terms {...props} key={Date.now()} />} />
            <Route exact path='/productmenu/:menu' render={(props) => <ProductMenu {...props} user={this.state.user} key={Date.now()} />} />
            <Route exact path='/productcategory/:menu/:category' render={(props) => <ProductCategory {...props} user={this.state.user} key={Date.now()} />} />
            <Route exact path='/products/:code' render={(props) => <ProductPage {...props} user={this.state.user} key={Date.now()} />} />
            <Route exact path='/favourite' render={(props) => <FavouritePage user={this.state.user}  {...props} key={Date.now()} />} />
            <Route exact path='/cart' render={(props) => <CartPage {...props} user={this.state.user} key={Date.now()} />} />
            <Route exact path="/user/login" render={(props) => <UserLogin user={this.state.user} setUser={this.setUser}   {...props} key={Date.now()} />} />
            <Route exact path="/user/register" render={(props) => <RegisterPage user={this.state.user} setUser={this.setUser}  {...props} key={Date.now()} />} />
            <Route exact path='/user/forget' render={(props) => <ForgetPasswordPage {...props} key={Date.now()} />} />
            <Route exact path='/user/reset/:id' render={(props) => <ResetPassword {...props} key={Date.now()} />} />
            <Route exact path='/user/profile' render={(props) => <ProfilePage user={this.state.user} setUser={this.setUser} {...props} key={Date.now()} />} />

          </Router>
          <Footer />
        </Suspense>
      </Fragment>

    )
  }
}

export default AppRoute;
