class AppURL {
  static BaseURL = "https://santript.in/admin/api/"
  static VisitorDetails = this.BaseURL + "getvisitor"
  static GetNews = this.BaseURL + "news"
  static PostContact = this.BaseURL + "contact"
  static GetContactUs = this.BaseURL + "contactus"
  static GetContactUsText = this.BaseURL + "contactustext"
  static GetSocialLinks = this.BaseURL + "getsociallinks"
  static GetLogo = this.BaseURL + "logo"
  static GetFavicon = this.BaseURL + "favicon"
  static GetSlider = this.BaseURL + "allslider"
  static GetHomeAbout = this.BaseURL + "homeabout"
  static GetHomeServices = this.BaseURL + "homeservices"
  static GetHomeFeatures = this.BaseURL + "homefeatures"
  static GetHomeGallery = this.BaseURL + "gallery"
  static GetHomeClients = this.BaseURL + "clients"
  static GetHomeTestimonial = this.BaseURL + "testimonial"
  static GetHomeBrandLogos = this.BaseURL + "brandlogos"
  static GetHomeVideo = this.BaseURL + "video"
  static GetSuccessProfile = this.BaseURL + "successprofile"
  static GetHomeBlog = this.BaseURL + "blog"
  static GetHomeLatestNews = this.BaseURL + "latestnews"
  static GetHomeFaqs = this.BaseURL + "homefaqs"
  static GetPageMenu = this.BaseURL + "pagemenu"
  static GetMenu = this.BaseURL + "menu"
  static GetStaticMenu = this.BaseURL + "staticmenu"
  static GetHomeTeam = this.BaseURL + "team"
  static GetDownloads = this.BaseURL + "downloads"
  static PostBlogComments = this.BaseURL + "comments"
  static PostSubscribe = this.BaseURL + "subscribe"
  static GetWhatsappLimit = this.BaseURL + "whatsapplimit"
  static GetEmailLimit = this.BaseURL + "emaillimit"
  static GetPhoneLimit = this.BaseURL + "phonelimit"
  static GetContactUsLimit = this.BaseURL + "contactuslimit"
  static GetBlinkingText = this.BaseURL + "blinking"
  static GetFaqs = this.BaseURL + "faqs"
  static GetPrivacy = this.BaseURL + "privacy"
  static GetTerms = this.BaseURL + "terms"
  static GetProducts = this.BaseURL + "products"
  static GetProductsWithLimit = this.BaseURL + "products"
  static addToCart = this.BaseURL + "addtocart"


  static UserLogin = this.BaseURL + "login"
  static UserData = this.BaseURL + "user"
  static UserRegister = this.BaseURL + "register"
  static UserForgetPassword = this.BaseURL + "forgetpassword"
  static UserResetPassword = this.BaseURL + "resetpassword"

  static GetBlog(blog_url) {

    return this.BaseURL + "blog/" + blog_url;

  }


  static BlogCommentsList(blog_url) {

    return this.BaseURL + "comments/" + blog_url;

  }

  static GetJavaScript(javascript_position) {

    return this.BaseURL + "javascript/" + javascript_position;

  }

  static GetHeadings(block_name) {

    return this.BaseURL + "headings/" + block_name;

  }

  static GetBlockImages(block_name) {

    return this.BaseURL + "blockimages/" + block_name;

  }

  static GetBreadcrumbs(page_url) {

    return this.BaseURL + "breadcrumbs/" + page_url;

  }


  static GetPage(page_url) {

    return this.BaseURL + "page/" + page_url;

  }



  static GetPageGallery(page_url) {

    return this.BaseURL + "pagegallery/" + page_url;

  }



  static GetPageVideo(code) {

    return this.BaseURL + "pagevideo/" + code;

  }

  static GetPageDownloads(code) {

    return this.BaseURL + "pagedownloads/" + code;

  }

  static GetPageFaqs(code) {

    return this.BaseURL + "pagefaqs/" + code;

  }

  static GetPageFeatures(code) {

    return this.BaseURL + "pagefeatures/" + code;

  }

  static GetPageContact(code) {

    return this.BaseURL + "pagecontact/" + code;

  }

  static GetPageServices(code) {

    return this.BaseURL + "pageservices/" + code;

  }

  static GetSeo(code) {

    return this.BaseURL + "seo/" + code;

  }

  static GetStaticSeo(code) {

    return this.BaseURL + "staticseo/" + code;

  }

  static GetBlogSeo(blog_url) {

    return this.BaseURL + "blogseo/" + blog_url;

  }

  static GetRating(code) {

    return this.BaseURL + "rating/" + code;

  }

  static GetOffers(code) {

    return this.BaseURL + "offers/" + code;

  }

  static GetWhatsapp(contact_title) {

    return this.BaseURL + "whatsapp/" + contact_title;

  }


  static GetPhone(contact_title) {

    return this.BaseURL + "phone/" + contact_title;

  }



  static GetEmail(contact_title) {

    return this.BaseURL + "email/" + contact_title;

  }


  static GetProductPage(code) {

    return this.BaseURL + "products/" + code;

  }

  static GetProductsByRemark(remark) {

    return this.BaseURL + "productsbyremark/" + remark;

  }

  static GetProductsByMenu(menu) {

    return this.BaseURL + "productsbymenu/" + menu;

  }

  static GetProductsByCategory(menu, category) {

    return this.BaseURL + "productsbycategory/" + menu + "/" + category;

  }

  static GetSimilarProducts(category) {

    return this.BaseURL + "similar/" + category;

  }



  static ReviewList(code) {

    return this.BaseURL + "reviewlist/" + code;

  }



  static cartCount(product_code) {

    return this.BaseURL + "cartcount/" + product_code;

  }


  static AddFavourite(product_code, email) {

    return this.BaseURL + "favourite/" + product_code + "/" + email;

  }

  static FavouriteList(email) {

    return this.BaseURL + "favouritelist/" + email;

  }

  static FavouriteRemove(product_code, email) {

    return this.BaseURL + "favouriteremove/" + product_code + "/" + email;

  }

  static CartList(email) {

    return this.BaseURL + "cartlist/" + email;

  }


  static RemoveCartList(id) {

    return this.BaseURL + "removecartlist/" + id;

  }


  static CartItemPlus(id, quantity, price) {

    return this.BaseURL + "cartitemplus/" + id + "/" + quantity + "/" + price;

  }

  static CartItemMinus(id, quantity, price) {

    return this.BaseURL + "cartitemminus/" + id + "/" + quantity + "/" + price;

  }

  static GetPageUrl(code) {

    return this.BaseURL + "menu/" + code;

  }


}

export default AppURL