import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AppURL from '../../api/AppURL';
import axios from 'axios';

const FooterStaticMenu = React.lazy(() => import("./FooterStaticMenu"))

const FooterContactWithOutEmail = React.lazy(() => import("./FooterContactWithOutEmail"))
const FooterAddressWithOutContact = React.lazy(() => import("./FooterAddressWithOutContact"))
const FooterWithEmail = React.lazy(() => import("./FooterWithEmail"))
const FooterCategoryLinks = React.lazy(() => import("./FooterCategoryLinks"))
const FooterMenuLinks = React.lazy(() => import("./FooterMenuLinks"))


const ContactPosition = React.lazy(() => import("./ContactPosition"))
const CopyRights = React.lazy(() => import("./CopyRights"))
const Logo = React.lazy(() => import("./Logo"))
const ContactDetails = React.lazy(() => import("./ContactDetails"))
const Socialmedia = React.lazy(() => import("./Socialmedia"))
const FooterAddress = React.lazy(() => import("./FooterAddress"))
const Subscribe = React.lazy(() => import("./Subscribe"))
const JavaScriptBottom = React.lazy(() => import("./JavaScriptBottom"))
const ScrollToTop = React.lazy(() => import("./ScrollToTop"))
const ApplyPosition = React.lazy(() => import("./ApplyPosition"))

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            FooterMenuData: [],
            StaticMenuData: [],
            about_text: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetHomeAbout).then(response => {

            let JsonData = (response.data['Home About'])[0];
            this.setState({
                about_text: JsonData['about_text']

            });


        }).catch(error => {

        });

        axios.post(AppURL.GetMenu).then(response => {
            this.setState({ FooterMenuData: response.data['Menu'] });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticMenu).then(response => {
            this.setState({ StaticMenuData: response.data['Static Menu'] });

        }).catch(error => {

        });

    }
    render() {
        return (
            <Fragment>
                <Suspense>
                    <div className="footer bg-image-3">
                        <Container>
                            <div className='footer-2'>
                                <Row>

                                    <Col lg={3} md={3} xl={3} xxl={3} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                                <h4>About Restaurant</h4>
                                                <div className='h4-line'></div>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.about_text.substring(0, 300) }} />
                                                <div className='footer-social'>
                                                    <Socialmedia />
                                                </div>
                                            </Col>


                                        </Row>
                                    </Col>
                                    <Col lg={3} md={3} xl={3} xxl={3} sm={12} xs={12}>
                                        <h4>Quick Links</h4>
                                        <div className='h4-line'></div>
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + "/"}><span className="fa fa-arrow-right"></span>Home</a></li>
                                            <FooterMenuLinks data={this.state.FooterMenuData} />
                                            <FooterStaticMenu staticdata={this.state.StaticMenuData} />
                                        </ul>

                                    </Col>


                                    <Col lg={3} md={3} xl={3} xxl={3} sm={12} xs={12}>
                                        <h4>Our Menu</h4>
                                        <div className='h4-line'></div>
                                        <ul>
                                            <FooterCategoryLinks data={this.state.FooterMenuData} />
                                        </ul>
                                    </Col>

                                    <Col lg={3} md={3} xl={3} xxl={3} sm={12} xs={12}>
                                        <h4>Contact Us</h4>
                                        <div className='h4-line'></div>
                                        <FooterAddress />
                                    </Col>
                                </Row>

                            </div >
                        </Container >
                        <CopyRights />
                    </div>



                    <ScrollToTop />
                    <JavaScriptBottom />
                </Suspense>
            </Fragment >


        )
    }
}

export default Footer;	